<template>
  <div>
    <!--begin::Content header-->

    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin text-center">
      <div class=" mb-10 mb-lg-20">
        <img src="media/logos/logo-letter-123.png" class="h-25 w-50" />
      </div>

      <!--begin::Form--><span>
      <b-form class="form w-50" @submit.stop.prevent="onSubmit" style="margin-left: auto;margin-right: auto;">
        <b-form-group id="example-input-group-1" label label-for="example-input-1">

          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            id="example-input-1"
            name="example-input-1"
            v-model="$v.form.email.$model"
            placeholder="email"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >Email is required and a valid email address.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="example-input-group-2" label label-for="example-input-2">
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            type="password"
            id="example-input-2"
            name="example-input-2"
            placeholder="password"
            v-model="$v.form.password.$model"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">Password is required.</b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
          <a
            href="#"
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >Forgot Password ?</a>
          <button
            ref="kt_login_signin_submit"
            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
          >Sign In</button>
        </div>
        <!--end::Action-->
      </b-form>
      </span>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      },
      info: null
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send login request
        fetch(
          "https://cleancity.krueltech.com/dev/api/api.php?action=login&email=" +
            email +
            "&password=" +
            password
        )
          .then(res => res.json())
          .then(data => {
            if (data.error == true) {
              alert(data.message);
            } else {
              localStorage.setItem("name", data.user[0].name);
              localStorage.setItem("email", data.user[0].email);
              localStorage.setItem("id", data.user[0].id);
              this.$router.push({ name: "dashboard" });
            } 
          })
          .catch(console.log);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
